import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import RainsignCardData from '../Data/RainsignCardData.json'

const AppRainsignCardWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  background-color: #f5f5f5;
  .RainsignHeading {
    text-align: center;
    padding: 50px 0px 10px 0px;
  }
  .RainsignCardMainSection {
    padding: 0px 30px 50px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .RainsignCardView {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;
    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .RainsigncardDisplaySection {
    width: 300px;
    height: auto;
    /* box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%); */
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  /* .RainsigncardDisplaySection:hover {
    box-shadow: 0px 3px 7px 0px rgb(160 161 163 / 88%);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom: 2px solid #34a1d5;
    border-top: 2px solid #34a1d5;
    background-color: #fff;
  } */
  .RainsignImage {
    width: 101px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 35px;
    display: flex;
  }
  .RainsignCardHeading {
    text-align: center;
    color: #34a1d5;
    margin: auto;
    font-size: 1.5rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .RainsignPara {
    padding: 10px;
    text-align: center;
    font-size: 1rem;
  }
  .RainsignCardPara {
    padding-bottom: 0;
    margin-bottom: 30px;
    text-align: center;
  }
`

export const AppRainsignCard = () => {
  return (
    <AppRainsignCardWapper>
      <ReUseHTwotag Heading="Why RainSign?" HeadingStyle="RainsignHeading" />
      <ReUsePtag
        para="Rapidly improve supplier contract processes from creation to signatures."
        paraStyle="RainsignCardPara"
      />
      <div className="RainsignCardMainSection">
        <div className="RainsignCardView">
          {RainsignCardData.map((data, index) => {
            return (
              <div className="RainsigncardDisplaySection" key={index}>
                <div>
                  <ReuseImgTag ImageStyle="RainsignImage" ImagePath={data.ImagePath} AltName={data.AltName} />
                </div>
                <div>
                  <ReUseHTwotag Heading={data.title} HeadingStyle="RainsignCardHeading" />
                  <ReUsePtag para={data.Para} paraStyle="RainsignPara" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </AppRainsignCardWapper>
  )
}
