import React from 'react'
import styled from 'styled-components'

export const ButtonOutlineWrapper = styled.button`
  position: relative;
  display: inline-block;
  padding: 0 12px;
  text-align: center;
  font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
  color: #46b6e8;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  text-decoration: none;
  box-sizing: border-box;
  background: #ffffff;
  -moz-transition: background 0.3s linear;
  /* WebKit */
  -webkit-transition: background 0.3s linear;
  /* Opera */
  -o-transition: background 0.3s linear;
  /* Standard */
  transition: background 0.3s linear;
  background-size: 400%;
  border-style: solid;
  border-width: 1px;
  border-color: #46b6e8;
  z-index: 1;
  width: fit-content;
  align-self: center;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #004991;
    border-color: #004991;
    .buttontext {
      background-color: transparent;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 22px;
      color: white;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    /* Firefox */
    -moz-transition: background 0.3s ease-in;
    /* WebKit */
    -webkit-transition: background 0.3s ease-in;
    /* Opera */
    -o-transition: background 0.3s ease-in;
    /* Standard */
    transition: background 0.3s ease-in;
    cursor: pointer;
    animation: animate 8s linear infinite;

    &::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }
  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .buttontextandicon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .logoicon {
    width: 28px;
    height: 28px;
    margin-right: 6px;
  }
  .buttontext {
    background-color: transparent;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 22px;
    color: #46b6e8;
    margin-top: 8px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const ButtonOutline = (props: any) => {
  return (
    <ButtonOutlineWrapper
      aria-label={props.label}
      type={props.type}
      onClick={props.onClick}
      style={{ height: 50, borderRadius: 25, fontSize: 22 }}>
      {props.cta}
    </ButtonOutlineWrapper>
  )
}

export const ButtonOutlineCompact = (props: any) => {
  return (
    <ButtonOutlineWrapper
      aria-label={props.label}
      type={props.type}
      onClick={props.onClick}
      style={{ height: 50, borderRadius: 25, fontSize: 22 }}>
      {props.cta}
    </ButtonOutlineWrapper>
  )
}

export const ButtonOutlineCompactWithLogo = (props: any) => {
  return (
    <ButtonOutlineWrapper
      aria-label={props.label}
      type={props.type}
      onClick={(evt) => {
        if (props.onClick) {
          props.onClick(evt)
        }
      }}
      style={{ height: 50, borderRadius: 25, fontSize: 19 }}
    >
      <div className="buttontextandicon">
        {/* <img src='https://storage.googleapis.com/raindroppublic/website_data/raindrop_logo.svg' className='logoicon' /> */}
        <p className="buttontext">{props.cta}</p>
      </div>
    </ButtonOutlineWrapper>
  )
}
