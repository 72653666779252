import React from 'react'
import { LinkHubspot } from './LinkHubspot'
import { ButtonOutlineCompactWithLogo } from './ButtonOutline'

export interface DemoRequestButtonProps {
  label: string
  style?: any
}

export const DemoRequestButton = (props: DemoRequestButtonProps) => {
  return (
    <LinkHubspot to='/demo' style={props.style}>
      <span className='sr-only'>Jump to ContactUs</span>
      <ButtonOutlineCompactWithLogo cta={props.label} label={props.label} />
    </LinkHubspot>
  )
}
